import React, {useRef} from 'react';
import HeaderNavItem from "./HeaderNavItem";
import Button from "../ui/Button";
import {FaUser, FaUsersCog} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {Menu} from "primereact/menu";
import {MenuItem} from "primereact/menuitem";
import {IoLogOutOutline} from "react-icons/io5";
import {FaGear} from "react-icons/fa6";

interface HeaderProps {
    page?: string;
}

const Header = ({page}: HeaderProps) => {
    const navigate = useNavigate();

    const profileMenu = useRef<Menu>(null);

    const items: MenuItem[] = [
        {
            label: 'Profile',
            icon: <FaUser className="mr-2"/>,
            disabled: true,
            command: () => {
                navigate('/profile', {replace: true});
            }
        },
        {
            label: 'Settings',
            icon: <FaGear className="mr-2"/>,
            disabled: true,
            command: () => {
                navigate('/settings', {replace: true});
            }
        },
        {
            label: 'Logout',
            icon: <IoLogOutOutline className="mr-2"/>,
            command: () => {
                navigate('/logout', {replace: true});
            }
        }
    ];

    return (
        <div className="w-full bg-slate-900 py-5 mb-7 shadow-lg">
            <header className="container flex items-center justify-between">
                <nav className="flex gap-x-12 flex-wrap">
                    <button onClick={() => navigate('/')}>
                        <h1 className="text-2xl font-bold text-white">New Friendland</h1>
                    </button>
                    <HeaderNavItem active={page === 'rulebook'} path="/rulebook">Rulebook</HeaderNavItem>
                    <HeaderNavItem active={page === 'zones'} path="/zones">Zones</HeaderNavItem>
                    <HeaderNavItem active={page === 'areas'} path="/areas">Areas</HeaderNavItem>
                    <HeaderNavItem active={page === 'elections'} path="/elections">Elections</HeaderNavItem>
                </nav>
                <nav className="flex gap-x-2">
                    <Button onClick={() => navigate('/admin')} size="small" rounded><FaUsersCog/></Button>
                    <Button onClick={(event) => profileMenu.current?.toggle(event)} size="small" rounded><FaUser/></Button>
                    <Menu model={items} popup ref={profileMenu} id="popup_menu_right" popupAlignment="right"/>
                </nav>
            </header>
        </div>

    );
};

export default Header;